var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-contents" },
    [
      _c("div", { staticClass: "contact-details-view" }, [
        _c("div", { staticClass: "tb-view-type" }, [
          _c("table", [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.event.title))]),
                _c("th", [
                  _c("div", { staticClass: "flex-event-r" }, [
                    _c("strong", [_vm._v("조회수")]),
                    _c("span", [_vm._v(_vm._s(_vm.event.viewCnt))]),
                    _c("i", { staticClass: "bu-line" }),
                    _c("strong", [_vm._v("작성일")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("$dateFormatter")(
                            _vm.event.createDate,
                            "YYYY-MM-DD"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _vm.event.eventContents !== null
                      ? _c(_vm.eventContents, {
                          tag: "component",
                          attrs: {
                            "event-id": _vm.event.eventId,
                            "content-id": _vm.id,
                          },
                        })
                      : _vm.event.eventPlace === 2
                      ? _c(_vm.eventComponent, {
                          tag: "component",
                          attrs: {
                            "event-id": _vm.event.eventId,
                            "notice-id": _vm.id,
                          },
                        })
                      : _c("div", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.event.contents,
                              expression: "event.contents",
                            },
                          ],
                          ref: "content",
                          on: { click: _vm.handleClick },
                        }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  _vm._l(_vm.event.attachFiles, function (item) {
                    return _c(
                      "ul",
                      { key: item.fileId, staticClass: "add-list" },
                      [
                        _c("li", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(
                                    item.fileId,
                                    item.fileName
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "i-file-add" }),
                              _vm._v(_vm._s(item.fileName) + " "),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex-r" }, [
          _c(
            "button",
            { staticClass: "btn-list", on: { click: _vm.eventList } },
            [_vm._v(" 목록으로 ")]
          ),
        ]),
        _c("div", { staticClass: "tb-pn-view-type" }, [
          _c("table", [
            _vm._m(1),
            _c("tbody", [
              _vm.event.nextEvent
                ? _c(
                    "tr",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.fetchEvent(_vm.event.nextEvent.id, true)
                        },
                      },
                    },
                    [
                      _c("th", [_vm._v("다음글")]),
                      _c("td", [
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.event.nextEvent.title)),
                          ]),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              _vm.event.nextEvent.createDate,
                              "YYYY-MM-DD"
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.event.prevEvent
                ? _c(
                    "tr",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.fetchEvent(_vm.event.prevEvent.id, true)
                        },
                      },
                    },
                    [
                      _c("th", [_vm._v("이전글")]),
                      _c("td", [
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.event.prevEvent.title)),
                          ]),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              _vm.event.prevEvent.createDate,
                              "YYYY-MM-DD"
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm.event.allowComments
        ? _c("comment-form", {
            ref: "commentForm",
            attrs: {
              "content-type": 7,
              "is-hook": _vm.event.eventPlace === 4,
              "member-only": _vm.event.eventPlace === 4 ? false : true,
            },
            on: { hook: _vm.commentPreProcess },
          })
        : _vm._e(),
      _vm.event.component && _vm.event.eventPlace === 4
        ? _c(_vm.popupComponent, {
            ref: "preProcessPopup",
            tag: "component",
            attrs: { "event-id": _vm.event.eventId },
            on: { close: _vm.closePreProcessPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "360px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "180px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "210px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }