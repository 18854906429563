<!-- eslint-disable semi -->
<template>
  <article class="contents">
    <div class="notice-area">
      <div class="tab-nav common-type">
        <ul>
          <li :class="{ 'on': query.isFinish === false }">
            <a @click="changeTab(false)">진행 중인 이벤트</a>
          </li>
          <li :class="{ 'on': query.isFinish }">
            <a @click="changeTab(true)">종료된 이벤트</a>
          </li>
        </ul>
      </div>

      <div v-show="view == false">
        <div class="search-wrap">
          <div class="left-col">
            <div class="select-wrap">
              <select v-model="query.searchType">
                <option
                  v-for="option in codes.searchType"
                  :key="option.code"
                  :value="option.code"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="search-bar">
              <div class="text-wrap">
                <input
                  v-model="query.keyword"
                  type="text"
                  placeholder="키워드를 입력하세요."
                  class="search-type"
                  @keyup.enter="fetchEvents()"
                >
                <button class="btn-search">
                  검색
                </button>
              </div>
            </div>
          </div>
          <div class="right-col event-btn-type">
            <button
              class="btn-thumb"
              :class="{ active: isActiveThum }"
              @click="activeThumb"
            >
              <span>썸네일보기</span>
            </button>
            <button
              class="btn-list"
              :class="{ active: isActiveList }"
              @click="activeList"
            >
              <span>리스트보기</span>
            </button>
          </div>
        </div>

        <div v-if="isActiveThum">
          <div
            v-if="isActiveThum"
            class="thumbnail-wrap"
          >
            <div class="event-thumb-list">
              <!-- 썸네일 8개씩 페이징 -->
              <!-- width: 296px; height: 210px; -->
              <div
                v-for="item in events"
                :key="item.id"
                class="thumbnail-item"
                style="margin-bottom: 30px;"
                @click="eventClick(item)"
              >
                <div
                  class="thumbnail-img"
                  :class="{ 'finish': item.isFinish }"
                >
                  <img
                    :src="item.filePath"
                    style="width: 100%; height: 100%; max-width: 100%;"
                  >
                </div>
                <div
                  class="title"
                  :class="{ 'finish': item.isFinish }"
                >
                  {{ item.title }} {{ (item.commentCount != 0 && item.allowComments ? `[${item.commentCount}]` : '') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 이벤트 리스트형 -->
        <div v-if="isActiveList">
          <div
            v-if="isActiveList"
            class="tb-list-type notice-type"
          >
            <table>
              <colgroup>
                <col style="width: 100px">
                <col style="width: 531px">
                <col style="width: 180px">
                <col style="width: 90px">
              </colgroup>
              <thead>
                <tr>
                  <th>순번</th>
                  <th>제목</th>
                  <th>작성일</th>
                  <th>조회수</th>
                </tr>
              </thead>
              <tbody>
                <!-- 한 페이지 12줄 씩 -->
                <tr
                  v-for="item in events"
                  :key="item.id"
                  :class="{ 'notice-fix': item.isFixed, 'finish': item.isFinish }"
                  @click="eventClick(item)"
                >
                  <td v-if="item.isFixed">
                    <i class="ico-notice" />
                  </td>
                  <td v-else>
                    {{ item.id }}
                  </td>
                  <td>
                    <div class="reply-list-td">
                      <p class="ellipsis-txt">
                        <a>
                          {{ item.title }}
                        </a>
                      </p>
                      <span v-if="item.allowComments && item.commentCount > 0">[{{ item.commentCount }}]</span>
                    </div>
                  </td>
                  <td>{{ item.createDate | $dateFormatter('YYYY. MM. DD') }}</td>
                  <td>{{ item.viewCnt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <pagination
          :page="currentPage"
          :records="totalRecord"
          :per="pageCount"
          @paging="pageUpdate"
        />
      </div>

      <div v-if="view">
        <event-view
          :id="noticeId"
          ref="eventView"
          @statUpdate="statUpdate"
        />
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </article>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'
import EventView from '@/views/apps/event/EventView.vue'
import { mapGetters } from 'vuex'
import { setStatistics, setStatisticsAwait } from '@/common/statistics/service'

export default {
  components: {
    Pagination,
    EventView
  },
  data() {
    return {
      events: [],
      query: {
        keyword: '',
        searchType: 1,
        page: 1,
        isFinish: false,
      },
      codes: {
        searchType: [],
      },
      currentPage: 1,
      totalRecord: 0,
      pageCount: 8,
      isActiveThum: true,
      isActiveList: false,
      view: false,
      noticeId: 0,
      // 진입 통계 ID (ReferenceId로 사용)
      entry: null,
      entryStatsId: null,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
      infoSetting: 'infoData/getInfoSetting',
    })
  },
  watch: {
    params() {
      if (this.params && this.params.route === this.$route.name) this.eventDetail(this.params.id, this.params.entry)
    },
    $route() {
      if (this.$route.query && this.$route.query.id) this.eventDetail(Number(this.$route.query.id), this.$route.query.entry)
    },
    infoSetting() {
      if (this.$route.query.id) this.eventDetail(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.eventDetail(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.eventDetail(this.params.id, this.params.entry)
    }
  },
  mounted() {
    this.fetchCodes()
    this.fetchEvents()

    if (this.infoSetting) {
      if (this.$route.query.id) this.eventDetail(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.eventDetail(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.eventDetail(this.params.id, this.params.entry)
    }
  },
  methods: {
    changeTab(isFinish) {
      this.query.isFinish = isFinish
      this.currentPage = 1

      this.view = false
      this.fetchEvents()
    },
    fetchCodes() {
      axios.get('/fu/event/codes')
        .then((rs) => {
          const { searchType } = rs.data

          this.codes.searchType = searchType
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchEvents(page = this.currentPage, perPage = this.pageCount) {
      axios.post('/fu/event/list', {
        search: {
          query: this.query,
        },
        pagination: {
          number: page,
          count: perPage,
        },
      })
        .then((rs) => {
          const { items, totalRecord } = rs.data

          this.events = items
          this.totalRecord = totalRecord
          this.totalListRecord = totalRecord
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    eventClick(item) {
      if (item.isFinish) return

      const subPageEntry = 120 // 120: 서브페이지 진입
      this.eventDetail(item.id, subPageEntry)
    },
    async eventDetail(id, entry = 125) {
      const statsId = await setStatisticsAwait(entry, id, this.entryStatsId)

      if (!this.entry) this.entry = Number(entry)
      if (!this.entryStatsId) this.entryStatsId = statsId

      this.noticeId = Number(id)
      this.view = true

      if (this.params) this.$store.commit('util/setParams', null)
    },
    statUpdate(id) {
      if (this.entry) setStatistics(this.entry, id, this.entryStatsId)
    },
    pageUpdate(page) {
      this.currentPage = page

      this.fetchEvents()
    },
    activeThumb() {
      this.isActiveThum = true
      this.isActiveList = false

      this.currentPage = 1
      this.pageCount = 8

      this.fetchEvents()
    },
    activeList() {
      this.isActiveThum = false
      this.isActiveList = true

      this.currentPage = 1
      this.pageCount = 12

      this.fetchEvents()
    },
  },
}
</script>
